const Button = () => {
  return (
    <div className="buttonsp4d">
      <a href="http://mobilhino99.com/login" target="_blank" className="button bgBtnRed text-sm px-btn rounded">Masuk</a>
      <a href="mobilhino99.com/daftar" target="_blank" className="button bgBtnBlue text-sm px-btn rounded">Daftar Sekarang</a>
      <a href="https://secure.livechatinc.com/customer/action/open_chat?license_id=16244283" target="_blank" className="button bgBtnYellow text-sm px-btn rounded">Live Chat</a>
    </div>
  );
};

export default Button;